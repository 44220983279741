<template>
  <div class="eyeBiologyTest" id="eyeBiologyTest">
    <h3>眼生物测量</h3>

    <el-button type="primary" @click="showsjqs=true">数据趋势</el-button>

    <ul class="xxxx">
      <li></li>
      <li>眼轴</li>
      <li>前房深度</li>
      <li>角膜直径</li>
      <li>晶状体厚度</li>
    </ul>
    <ul class="xxxx">
      <li>OD</li>
      <li>
        <el-input @keydown.native="keyd($event)" :class="[srjc(postData.CL_RE_OM_OD_OcularAxis, 18, 35)?'cw':'', srval==='CL_RE_OM_OD_OcularAxis'?'xz':'']" class="pj" @focus="getgb(postData.CL_RE_OM_OD_OcularAxis, 'CL_RE_OM_OD_OcularAxis')" v-model="postData.CL_RE_OM_OD_OcularAxis" placeholder="请输入内容"></el-input>
        <span>mm</span>
      <li>
        <el-input @keydown.native="keyd($event)" :class="[srjc(postData.CL_RE_OM_OD_DAC, 0, 10)?'cw':'', srval==='CL_RE_OM_OD_DAC'?'xz':'']" class="pj" @focus="getgb(postData.CL_RE_OM_OD_DAC, 'CL_RE_OM_OD_DAC')" v-model="postData.CL_RE_OM_OD_DAC" placeholder="请输入内容"></el-input>
        <span>mm</span>
      </li>
      <li>
        <el-input @keydown.native="keyd($event)" :class="[srjc(postData.CL_RE_OM_OD_DC, 8, 15)?'cw':'', srval==='CL_RE_OM_OD_DC'?'xz':'']" class="pj" @focus="getgb(postData.CL_RE_OM_OD_DC, 'CL_RE_OM_OD_DC')" v-model="postData.CL_RE_OM_OD_DC" placeholder="请输入内容"></el-input>
        <span>mm</span>
      </li>
      <li>
        <el-input @keydown.native="keyd($event)" :class="[srjc(postData.CL_RE_OM_OD_TEL, 2, 10)?'cw':'', srval==='CL_RE_OM_OD_TEL'?'xz':'']" class="pj" @focus="getgb(postData.CL_RE_OM_OD_TEL, 'CL_RE_OM_OD_TEL')" v-model="postData.CL_RE_OM_OD_TEL" placeholder="请输入内容"></el-input>
        <span>mm</span>
      </li>
    </ul>
    <ul class="xxxx">
      <li>OS</li>
      <li>
        <el-input @keydown.native="keyd($event)" :class="[srjc(postData.CL_RE_OM_OS_OcularAxis, 18, 35)?'cw':'', srval==='CL_RE_OM_OS_OcularAxis'?'xz':'']" class="pj" @focus="getgb(postData.CL_RE_OM_OS_OcularAxis, 'CL_RE_OM_OS_OcularAxis')" v-model="postData.CL_RE_OM_OS_OcularAxis" placeholder="请输入内容"></el-input>
        <span>mm</span>
      </li>
      <li>
        <el-input @keydown.native="keyd($event)" :class="[srjc(postData.CL_RE_OM_OS_DAC, 0, 10)?'cw':'', srval==='CL_RE_OM_OS_DAC'?'xz':'']" class="pj" @focus="getgb(postData.CL_RE_OM_OS_DAC, 'CL_RE_OM_OS_DAC')" v-model="postData.CL_RE_OM_OS_DAC" placeholder="请输入内容"></el-input>
        <span>mm</span>
      </li>
      <li>
        <el-input @keydown.native="keyd($event)" :class="[srjc(postData.CL_RE_OM_OS_DC, 8, 15)?'cw':'', srval==='CL_RE_OM_OS_DC'?'xz':'']" class="pj" @focus="getgb(postData.CL_RE_OM_OS_DC, 'CL_RE_OM_OS_DC')" v-model="postData.CL_RE_OM_OS_DC" placeholder="请输入内容"></el-input>
        <span>mm</span>
      </li>
      <li>
        <el-input @keydown.native="keyd($event)" :class="[srjc(postData.CL_RE_OM_OS_TEL, 2, 10)?'cw':'', srval==='CL_RE_OM_OS_TEL'?'xz':'']" class="pj" @focus="getgb(postData.CL_RE_OM_OS_TEL, 'CL_RE_OM_OS_TEL')" v-model="postData.CL_RE_OM_OS_TEL" placeholder="请输入内容"></el-input>
        <span>mm</span>
      </li>
    </ul>

    <ul class="xxxx">
      <li></li>
      <li>瞳孔直径</li>
      <li>角膜中央厚度</li>
      <li>玻璃体腔长度</li>
    </ul>
    <ul class="xxxx">
      <li>OD</li>
      <li>
        <el-input @keydown.native="keyd($event)" :class="[srjc(postData.CL_RE_OM_OD_PD, 1, 10)?'cw':'', srval==='CL_RE_OM_OD_PD'?'xz':'']" class="pj" @focus="getgb(postData.CL_RE_OM_OD_PD, 'CL_RE_OM_OD_PD')" v-model="postData.CL_RE_OM_OD_PD" placeholder="请输入内容"></el-input>
        <span>mm</span>
      </li>
      <li>
        <el-input @keydown.native="keyd($event)" :class="[srjc(postData.CL_RE_OM_OD_CCT, 400, 700)?'cw':'', srval==='CL_RE_OM_OD_CCT'?'xz':'']" class="pj" @focus="getgb(postData.CL_RE_OM_OD_CCT, 'CL_RE_OM_OD_CCT')" v-model="postData.CL_RE_OM_OD_CCT" placeholder="请输入内容"></el-input>
        <span>um</span>
      </li>
      <li>
        <el-input @keydown.native="keyd($event)" :class="[srjc(postData.CL_RE_OM_OD_LI, 5, 25)?'cw':'', srval==='CL_RE_OM_OD_LI'?'xz':'']" class="pj" @focus="getgb(postData.CL_RE_OM_OD_LI, 'CL_RE_OM_OD_LI')" v-model="postData.CL_RE_OM_OD_LI" placeholder="请输入内容"></el-input>
        <span>mm</span>
      </li>
    </ul>
    <ul class="xxxx">
      <li>OS</li>
      <li>
        <el-input @keydown.native="keyd($event)" :class="[srjc(postData.CL_RE_OM_OS_PD, 1, 10)?'cw':'', srval==='CL_RE_OM_OS_PD'?'xz':'']" class="pj" @focus="getgb(postData.CL_RE_OM_OS_PD, 'CL_RE_OM_OS_PD')" v-model="postData.CL_RE_OM_OS_PD" placeholder="请输入内容"></el-input>
        <span>mm</span>
      </li>
      <li>
        <el-input @keydown.native="keyd($event)" :class="[srjc(postData.CL_RE_OM_OS_CCT, 400, 700)?'cw':'', srval==='CL_RE_OM_OS_CCT'?'xz':'']" class="pj" @focus="getgb(postData.CL_RE_OM_OS_CCT, 'CL_RE_OM_OS_CCT')" v-model="postData.CL_RE_OM_OS_CCT" placeholder="请输入内容"></el-input>
        <span>um</span>
      </li>
      <li>
        <el-input @keydown.native="keyd($event)" :class="[srjc(postData.CL_RE_OM_OS_LI, 5, 25)?'cw':'', srval==='CL_RE_OM_OS_LI'?'xz':'']" class="pj" @focus="getgb(postData.CL_RE_OM_OS_LI, 'CL_RE_OM_OS_LI')" v-model="postData.CL_RE_OM_OS_LI" placeholder="请输入内容"></el-input>
        <span>mm</span>
      </li>
    </ul>

    <ul class="xxxx">
      <li></li>
      <li>角膜最平坦K值(K1/R1)</li>
      <li></li>
      <li>角膜最陡峭K值(K2/R2)</li>
      <li></li>
    </ul>
    <ul class="xxxx">
      <li>OD</li>
      <li>
<!--        //:class="postData.CL_RE_OM_OD_FlatK1&&(postData.CL_RE_OM_OD_FlatK1 < 30 || postData.CL_RE_OM_OD_FlatK1 > 60)?'cw':''"-->
<!--        <el-input class="pj" @change="zh('CL_RE_OM_OD_FlatK1', 1)" :class="postData.CL_RE_OM_OD_FlatK1&&(Number(postData.CL_RE_OM_OD_FlatK1) < 0 || postData.CL_RE_OM_OD_FlatK1 > 60)?'cw':''"  v-model="postData.CL_RE_OM_OD_FlatK1" placeholder="请输入内容"></el-input>-->
        <el-input @keydown.native="keyd($event)" :class="[srjc(postData.CL_RE_OM_OD_FlatK1, 0, 60)?'cw':'', srval==='CL_RE_OM_OD_FlatK1'?'xz':'']" class="pj" @focus="getgb(postData.CL_RE_OM_OD_FlatK1, 'CL_RE_OM_OD_FlatK1')" v-model="postData.CL_RE_OM_OD_FlatK1" placeholder="请输入内容"></el-input>
        <span>@</span>
      </li>
      <li>
<!--        //:class="postData.CL_RE_OM_OD_FlatK2&&(postData.CL_RE_OM_OD_FlatK2 < 0 || postData.CL_RE_OM_OD_FlatK2 > 180)?'cw':''"-->
<!--        <el-input class="pj" @change="zh('CL_RE_OM_OD_FlatK2', 1)" :class="postData.CL_RE_OM_OD_FlatK2&&(postData.CL_RE_OM_OD_FlatK2 < 0 || postData.CL_RE_OM_OD_FlatK2 > 180)?'cw':''" v-model="postData.CL_RE_OM_OD_FlatK2" placeholder="请输入内容"></el-input>-->
        <el-input @keydown.native="keyd($event)" :class="[srjc(postData.CL_RE_OM_OD_FlatK2, 0, 180)?'cw':'', srval==='CL_RE_OM_OD_FlatK2'?'xz':'']" class="pj" @focus="getgb(postData.CL_RE_OM_OD_FlatK2, 'CL_RE_OM_OD_FlatK2')" v-model="postData.CL_RE_OM_OD_FlatK2" placeholder="请输入内容"></el-input>
      </li>
      <li>
<!--        //:class="postData.CL_RE_OM_OD_SteepK1&&(postData.CL_RE_OM_OD_SteepK1 < 35 || postData.CL_RE_OM_OD_SteepK1 > 60)?'cw':''"-->
<!--        <el-input class="pj" @change="zh('CL_RE_OM_OD_SteepK1', 1)" :class="postData.CL_RE_OM_OD_SteepK1&&(postData.CL_RE_OM_OD_SteepK1 < 0 || postData.CL_RE_OM_OD_SteepK1 > 70)?'cw':''" v-model="postData.CL_RE_OM_OD_SteepK1" placeholder="请输入内容"></el-input>-->
        <el-input @keydown.native="keyd($event)" :class="[srjc(postData.CL_RE_OM_OD_SteepK1, 0, 70)?'cw':'', srval==='CL_RE_OM_OD_SteepK1'?'xz':'']" class="pj" @focus="getgb(postData.CL_RE_OM_OD_SteepK1, 'CL_RE_OM_OD_SteepK1')" v-model="postData.CL_RE_OM_OD_SteepK1" placeholder="请输入内容"></el-input>
        <span>@</span>
      </li>
      <li>
<!--        //:class="postData.CL_RE_OM_OD_SteepK2&&(postData.CL_RE_OM_OD_SteepK2 < 0 || postData.CL_RE_OM_OD_SteepK2 > 180)?'cw':''"-->
<!--        <el-input class="pj" @change="zh('CL_RE_OM_OD_SteepK2', 1)" :class="postData.CL_RE_OM_OD_SteepK2&&(postData.CL_RE_OM_OD_SteepK2 < 0 || postData.CL_RE_OM_OD_SteepK2 > 180)?'cw':''" v-model="postData.CL_RE_OM_OD_SteepK2" placeholder="请输入内容"></el-input>-->
        <el-input @keydown.native="keyd($event)" :class="[srjc(postData.CL_RE_OM_OD_SteepK2, 0, 180)?'cw':'', srval==='CL_RE_OM_OD_SteepK2'?'xz':'']" class="pj" @focus="getgb(postData.CL_RE_OM_OD_SteepK2, 'CL_RE_OM_OD_SteepK2')" v-model="postData.CL_RE_OM_OD_SteepK2" placeholder="请输入内容"></el-input>
      </li>
    </ul>
    <ul class="xxxx">
      <li>OS</li>
      <li>
<!--        //:class="postData.CL_RE_OM_OS_FlatK1&&(postData.CL_RE_OM_OS_FlatK1 < 30 || postData.CL_RE_OM_OS_FlatK1 > 60)?'cw':''"-->
<!--        <el-input class="pj" @change="zh('CL_RE_OM_OS_FlatK1', 1)" :class="postData.CL_RE_OM_OS_FlatK1&&(postData.CL_RE_OM_OS_FlatK1 < 0 || postData.CL_RE_OM_OS_FlatK1 > 60)?'cw':''" v-model="postData.CL_RE_OM_OS_FlatK1" placeholder="请输入内容"></el-input>-->
        <el-input @keydown.native="keyd($event)" :class="[srjc(postData.CL_RE_OM_OS_FlatK1, 0, 60)?'cw':'', srval==='CL_RE_OM_OS_FlatK1'?'xz':'']" class="pj" @focus="getgb(postData.CL_RE_OM_OS_FlatK1, 'CL_RE_OM_OS_FlatK1')" v-model="postData.CL_RE_OM_OS_FlatK1" placeholder="请输入内容"></el-input>
        <span>@</span>
      </li>
      <li>
<!--        //:class="postData.CL_RE_OM_OS_FlatK2&&(postData.CL_RE_OM_OS_FlatK2 < 0 || postData.CL_RE_OM_OS_FlatK2 > 180)?'cw':''"-->
<!--        <el-input class="pj" @change="zh('CL_RE_OM_OS_FlatK2', 1)" :class="postData.CL_RE_OM_OS_FlatK2&&(postData.CL_RE_OM_OS_FlatK2 < 0 || postData.CL_RE_OM_OS_FlatK2 > 180)?'cw':''" v-model="postData.CL_RE_OM_OS_FlatK2" placeholder="请输入内容"></el-input>-->
        <el-input @keydown.native="keyd($event)" :class="[srjc(postData.CL_RE_OM_OS_FlatK2, 0, 180)?'cw':'', srval==='CL_RE_OM_OS_FlatK2'?'xz':'']" class="pj" @focus="getgb(postData.CL_RE_OM_OS_FlatK2, 'CL_RE_OM_OS_FlatK2')" v-model="postData.CL_RE_OM_OS_FlatK2" placeholder="请输入内容"></el-input>
      </li>
      <li>
<!--        :class="postData.CL_RE_OM_OS_SteepK1&&(postData.CL_RE_OM_OS_SteepK1 < 35 || postData.CL_RE_OM_OS_SteepK1 > 60)?'cw':''"-->
<!--        <el-input class="pj" @change="zh('CL_RE_OM_OS_SteepK1', 1)" :class="postData.CL_RE_OM_OS_SteepK1&&(postData.CL_RE_OM_OS_SteepK1 < 0 || postData.CL_RE_OM_OS_SteepK1 > 60)?'cw':''" v-model="postData.CL_RE_OM_OS_SteepK1" placeholder="请输入内容"></el-input>-->
        <el-input @keydown.native="keyd($event)" :class="[srjc(postData.CL_RE_OM_OS_SteepK1, 0, 70)?'cw':'', srval==='CL_RE_OM_OS_SteepK1'?'xz':'']" class="pj" @focus="getgb(postData.CL_RE_OM_OS_SteepK1, 'CL_RE_OM_OS_SteepK1')" v-model="postData.CL_RE_OM_OS_SteepK1" placeholder="请输入内容"></el-input>
        <span>@</span>
      </li>
      <li>
<!--        :class="postData.CL_RE_OM_OS_SteepK2&&(postData.CL_RE_OM_OS_SteepK2 < 0 || postData.CL_RE_OM_OS_SteepK2 > 180)?'cw':''"-->
<!--        <el-input class="pj" @change="zh('CL_RE_OM_OS_SteepK2', 1)" :class="postData.CL_RE_OM_OS_SteepK2&&(postData.CL_RE_OM_OS_SteepK2 < 0 || postData.CL_RE_OM_OS_SteepK2 > 180)?'cw':''" v-model="postData.CL_RE_OM_OS_SteepK2" placeholder="请输入内容"></el-input>-->
        <el-input @keydown.native="keyd($event)" :class="[srjc(postData.CL_RE_OM_OS_SteepK2, 0, 180)?'cw':'', srval==='CL_RE_OM_OS_SteepK2'?'xz':'']" class="pj" @focus="getgb(postData.CL_RE_OM_OS_SteepK2, 'CL_RE_OM_OS_SteepK2')" v-model="postData.CL_RE_OM_OS_SteepK2" placeholder="请输入内容"></el-input>
      </li>
    </ul>

    <div class="tp">
      <UPFELES :imgtype="'OM'" :wjtype="'眼生物测量'" :datas.sync="contentData"></UPFELES>
<!--      <UPfile :type="'OM'" :imglists="upimglist"></UPfile>-->
<!--      <el-upload-->
<!--          action="#"-->
<!--          list-type="picture-card"-->
<!--          accept=".jpg,.jpeg,.png,.JPG,.JPEG,.PNG"-->
<!--          :file-list="fileLists"-->
<!--          ref="uptq"-->
<!--          :on-change="sc"-->
<!--          :auto-upload="false">-->
<!--&lt;!&ndash;        <i slot="default" class="el-icon-plus"></i>&ndash;&gt;-->
<!--        <div slot="file" slot-scope="{file}">-->
<!--          <img-->
<!--              class="el-upload-list__item-thumbnail"-->
<!--              :src="file.url" alt=""-->
<!--          >-->
<!--          <span class="el-upload-list__item-actions">-->
<!--        <span-->
<!--            class="el-upload-list__item-preview"-->
<!--            @click="handlePictureCardPreview(file)"-->
<!--        >-->
<!--          <i class="el-icon-zoom-in"></i>-->
<!--        </span>-->
<!--&lt;!&ndash;        <span&ndash;&gt;-->
<!--&lt;!&ndash;            v-if="!disabled"&ndash;&gt;-->
<!--&lt;!&ndash;            class="el-upload-list__item-delete"&ndash;&gt;-->
<!--&lt;!&ndash;            @click="handleDownload(file)"&ndash;&gt;-->
<!--&lt;!&ndash;        >&ndash;&gt;-->
<!--&lt;!&ndash;          <i class="el-icon-download"></i>&ndash;&gt;-->
<!--&lt;!&ndash;        </span>&ndash;&gt;-->
<!--        <span-->
<!--            v-if="!disabled"-->
<!--            class="el-upload-list__item-delete"-->
<!--            @click="handleRemove(file)"-->
<!--        >-->
<!--          <i class="el-icon-delete"></i>-->
<!--        </span>-->
<!--      </span>-->
<!--        </div>-->
<!--        <div>上传图片</div>-->
<!--      </el-upload>-->
<!--      <el-dialog :visible.sync="dialogVisible">-->
<!--        <img width="100%" :src="dialogImageUrl" alt="">-->
<!--      </el-dialog>-->
    </div>

<!--    <div style="text-align: left;padding-top: 20px;">-->
<!--      <el-select class="xzlex"  v-model="valTypeidx" placeholder="请选择类型">-->
<!--        <el-option-->
<!--            v-for="item in options"-->
<!--            :key="item.value"-->
<!--            :label="item.label"-->
<!--            :value="item.value">-->
<!--        </el-option>-->
<!--      </el-select>-->
<!--      <el-button @click="dqwj" type="info">读取文件</el-button>-->
<!--    </div>-->


    <div class="jksjmgs" v-if="showsjqs">
      <el-button style="position: absolute;right: 10px;top: 10px;" @click="showsjqs=false" type="danger">关闭</el-button>
      <iframe :src="qslj" frameborder="0"></iframe>
    </div>

    <KEYS v-if="showkeys" :keyval="keyval" @changeval="changeval"></KEYS>
  </div>
</template>

<script>
import pf from "@/publicFn/baseFn.js"
export default {
  name: "eyeBiologyTest",
  data() {
    return {
      contentData: {},
      upimglist: [],
      showsjqs: false,
      qslj: 'https://healthdata.visumall.com/?customerid=' + this.$store.state.users.CsUser.CustomerId+ "&companyid=" + this.$store.state.users.user.Company.CompanyId,
      postData: {
        CL_RE_OM_ChooseId: "",
        CL_RE_OM_OD_CCT: "",
        CL_RE_OM_OD_DAC: "",
        CL_RE_OM_OD_DC: "",
        CL_RE_OM_OD_FlatK1: "",
        CL_RE_OM_OD_FlatK2: "",
        CL_RE_OM_OD_LI: "",
        CL_RE_OM_OD_OcularAxis: "",
        CL_RE_OM_OD_PD: "",
        CL_RE_OM_OD_SteepK1: "",
        CL_RE_OM_OD_SteepK2: "",
        CL_RE_OM_OD_TEL: "",
        CL_RE_OM_OS_CCT: "",
        CL_RE_OM_OS_DAC: "",
        CL_RE_OM_OS_DC: "",
        CL_RE_OM_OS_FlatK1: "",
        CL_RE_OM_OS_FlatK2: "",
        CL_RE_OM_OS_LI: "",
        CL_RE_OM_OS_OcularAxis: "",
        CL_RE_OM_OS_PD: "",
        CL_RE_OM_OS_SteepK1: "",
        CL_RE_OM_OS_SteepK2: "",
        CL_RE_OM_OS_TEL: "",
        CL_RE_OM_Remarks: "",
        CL_RE_OM_isDone: false
      },
      dialogImageUrl: '',
      disabled: false,
      dialogVisible: false,
      fileLists: [],
      valTypeidx: 1,
      valType: '',
      options: [],
      baiduTo: '',
      oparr: [],
      kfid: '',

      showkeys: false,
      issr: false,
      srval: '',
      keyval: '',
      iszf: 0,
      isdrc: 1
    }
  },
  watch: {
    postData: {
      handler(n, o) {
        this.$store.commit('eyeTest/upEyeBiologyTest', n)
      },
      deep: true
    },
    contentData: {
      handler(n, o) {
        if (n) {
          for (let key in n) {
            if (n[key]) this.postData[key] = n[key]
          }
        }
        this.$store.commit('eyeTest/upEyeBiologyTest', this.postData)
      },
      deep: true
    }
  },
  created() {
    document.addEventListener('click', this.getXY, false)
    if (!this.$store.state.users.CsUser.CustomerId) {return false}

    this._api.eyeTest.getEyeBiologyTest()
    .then(res => {
      if (res.GetListResult && res.GetListResult.length > 0) {
        this.postData = this._clJson(res.GetListResult[0].S_OP_Json)
      }
    })

    // this._api.publicApi.getImgList('OM')
    //     .then(res => {
    //       let arr = []
    //       if (res.GetListResult && res.GetListResult.length > 0) {
    //         for (let i = 0; i < res.GetListResult.length; i++) {
    //           arr.push({
    //             name: res.GetListResult[i].UFId,
    //             url: res.GetListResult[i].FullURL,
    //           })
    //         }
    //         this.fileLists = arr
    //       }
    //     })

    // this._api.publicApi.getOCRType('眼生物测量')
    //     .then(res => {
    //       let arr = []
    //       this.baiduTo = res.BaiduToken
    //       if (res.GetListResult && res.GetListResult.length > 0) {
    //         this.oparr = JSON.parse(JSON.stringify(res.GetListResult))
    //         for (let i = 0; i < res.GetListResult.length; i++) {
    //           arr.push({
    //             value: i + 1,
    //             label: res.GetListResult[i].BDOCR_TempName
    //           })
    //         }
    //       }
    //       this.options = arr
    //     })
  },
  methods: {
    handleRemove(file) {
      this._api.publicApi.deleImg(file.name)
          .then(res => {
            if (res.DeleteByIdReturn) {
              let upwj = this.$refs.uptq.uploadFiles
              for (let i = 0; i < upwj.length; i++) {
                if (upwj[i]['url'] == file.url) {
                  upwj.splice(i, 1)
                }
              }
            }
          })
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    handleDownload(file) {
      //console.log(file);
    },

    sc(file, f) {
      let _this = this
      let reader = new FileReader(), imgBase64 = '';
      reader.readAsDataURL(file.raw)
      reader.onload = function (e) {
        _this._http.post(pf.getUrl('UploadFile_Upload'), {
          "token": {
            "TimeSpan": 1566827733,
            "sToken": "768DCAFFF869CD61372790C2738012EC"
          },
          o: {
            FileBase64: e.currentTarget.result.split(',')[1],
            ExtensionName: ".png",
            UFSaveType: 'OSSPrivate',
            UFReObject: "Choose",
            UFType: 'OM',
            UFReId: _this.$store.state.physicianVisits.xzConsulting
          }
        })
            .then(res => {
              if (res.Id) {
                file.name = res.Id
              }
            })
      }
    },

    dqwj() {
      if (!this.$store.state.users.employees) {
        return this.$alert('员工未登录请登录！', '提示')
            .then(res => {this.$router.push({path: '/employeesLanding'})})
      }

      if (!this.valTypeidx) {
        return this.$alert('请选择类型！', '提示')
      }

      if (!this.isdrc) return

      this.isdrc = 0

      this.wjsc()

      let str = this.$store.state.physicianVisits.xzConsulting.slice(0,8).toUpperCase()
      let name = this.$store.state.users.CsUser.CustomerName,
          ys = this.$store.state.users.employees.ExpertName,
          temid = this.oparr[this.valTypeidx-1].BDOCR_TempId
      let obj = {
        ChooseNo: str,
        Expert: ys,
        Customer: name,
        CheckType: "眼生物测量",
        TempId: temid,
        BDToken: this.baiduTo,
        PageSite: this.oparr[this.valTypeidx-1].BDOCR_PageSite,
        ImageSite: this.oparr[this.valTypeidx-1].BDOCR_ImageSite,
        "OcrId": this.oparr[this.valTypeidx-1].BDOCR_Id,
        "TempName": this.oparr[this.valTypeidx-1].BDOCR_BrandName,
        "CompanyId": this.$store.state.users.user.Company.CompanyId,
        "ShopId": this.$store.state.bases.shop.ShopId,
        "ChooseId": this.$store.state.physicianVisits.xzConsulting,
        "HealthCheckId": '',
        "CustomerId": this.$store.state.users.CsUser.CustomerId,
        "ExpertId": this.$store.state.users.employees?this.$store.state.users.employees.ExpertId:"00000000-0000-0000-0000-000000000000"
      }
      /*this._api.publicApi.OCRLfirst(this.oparr[this.valTypeidx-1].BDOCR_BrandName, this.oparr[this.valTypeidx-1].BDOCR_Id)
          .then(res => {
            // console.log(res)
            if (res.ReturnCode == 5000) {
              this.kfid = res.Result
              window.bound.selReport(JSON.stringify(obj));
            }else {
              this.$alert(res.displaymsg, '提示')
            }
          })*/
      window.bound.selReport(JSON.stringify(obj));
    },


    convertBase64UrlToBlob(urlData){
      let arr = urlData.split(','),
          bstr = window.atob(urlData), n = bstr.length, u8arr = new window.Uint8Array(n);
      while(n--){
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new window.Blob([u8arr], {type:'png'});
    },

    wjsc() {
      let _this = this
      window.getReportResult = function (res) {
        // let upwj = _this.$refs.uptq.uploadFiles
        _this.isdrc = 1
        let obj = JSON.parse(JSON.stringify(_this.postData))
        if (res.BaiduResult) {
          let datas = JSON.parse(res.BaiduResult).data
          if (datas.ret) {
            let obj = {
              CL_RE_OM_ChooseId: "",
              CL_RE_OM_OD_CCT: "",
              CL_RE_OM_OD_DAC: "",
              CL_RE_OM_OD_DC: "",
              CL_RE_OM_OD_FlatK1: "",
              CL_RE_OM_OD_FlatK2: "",
              CL_RE_OM_OD_LI: "",
              CL_RE_OM_OD_OcularAxis: "",
              CL_RE_OM_OD_PD: "",
              CL_RE_OM_OD_SteepK1: "",
              CL_RE_OM_OD_SteepK2: "",
              CL_RE_OM_OD_TEL: "",
              CL_RE_OM_OS_CCT: "",
              CL_RE_OM_OS_DAC: "",
              CL_RE_OM_OS_DC: "",
              CL_RE_OM_OS_FlatK1: "",
              CL_RE_OM_OS_FlatK2: "",
              CL_RE_OM_OS_LI: "",
              CL_RE_OM_OS_OcularAxis: "",
              CL_RE_OM_OS_PD: "",
              CL_RE_OM_OS_SteepK1: "",
              CL_RE_OM_OS_SteepK2: "",
              CL_RE_OM_OS_TEL: "",
              CL_RE_OM_Remarks: "",
              CL_RE_OM_isDone: false
            }
            for (let i = 0; i < datas.ret.length; i++) {
              let str = datas.ret[i].word
              str = str?str.toString():''
              switch (datas.ret[i].word_name) {
                case 'CL_RE_OM_OD_CCT':
                  obj.CL_RE_OM_OD_CCT = str
                  break
                case 'CL_RE_OM_OS_CCT':
                  obj.CL_RE_OM_OS_CCT = str
                  break
                case 'CL_RE_OM_OD_DAC':
                  obj.CL_RE_OM_OD_DAC = str
                  break
                case 'CL_RE_OM_OS_DAC':
                  obj.CL_RE_OM_OS_DAC = str
                  break
                case 'CL_RE_OM_OD_DC':
                  obj.CL_RE_OM_OD_DC = str
                  break
                case 'CL_RE_OM_OS_DC':
                  obj.CL_RE_OM_OS_DC = str
                  break
                case 'CL_RE_OM_OD_PD':
                  obj.CL_RE_OM_OD_PD = str
                  break
                case 'CL_RE_OM_OS_PD':
                  obj.CL_RE_OM_OS_PD = str
                  break
                case 'CL_RE_OM_OD_FlatK1':
                  obj.CL_RE_OM_OD_FlatK1 = datas.ret[i].word?datas.ret[i].word.toString():''
                  break
                case 'CL_RE_OM_OS_FlatK1':
                  obj.CL_RE_OM_OS_FlatK1 = datas.ret[i].word?datas.ret[i].word.toString():''
                  break
                case 'CL_RE_OM_OS_FlatK2':
                  obj.CL_RE_OM_OS_FlatK2 = datas.ret[i].word?datas.ret[i].word.toString():''
                  break
                case 'CL_RE_OM_OD_FlatK2':
                  obj.CL_RE_OM_OD_FlatK2 = datas.ret[i].word?datas.ret[i].word.toString():''
                  break
                case 'CL_RE_OM_OD_SteepK1':
                  obj.CL_RE_OM_OD_SteepK1 = datas.ret[i].word?datas.ret[i].word.toString():''
                  break
                case 'CL_RE_OM_OS_SteepK1':
                  obj.CL_RE_OM_OS_SteepK1 = datas.ret[i].word?datas.ret[i].word.toString():''
                  break
                case 'CL_RE_OM_OD_SteepK2':
                  obj.CL_RE_OM_OD_SteepK2 = datas.ret[i].word?datas.ret[i].word.toString():''
                  break
                case 'CL_RE_OM_OS_SteepK2':
                  obj.CL_RE_OM_OS_SteepK2 = datas.ret[i].word?datas.ret[i].word.toString():''
                  break
                case 'CL_RE_OM_OD_OcularAx':
                  obj.CL_RE_OM_OD_OcularAxis = str
                  break
                case 'CL_RE_OM_OS_OcularAx':
                  obj.CL_RE_OM_OS_OcularAxis = str
                  break
                case 'CL_RE_OM_OD_TEL':
                  obj.CL_RE_OM_OD_TEL = str
                  break
                case 'CL_RE_OM_OS_TEL':
                  obj.CL_RE_OM_OS_TEL = str
                  break
                case 'CL_RE_OM_OD_LI':
                  obj.CL_RE_OM_OD_LI = str
                  break
                case 'CL_RE_OM_OS_LI':
                  obj.CL_RE_OM_OS_LI = str
                  break
              }
            }
            _this.postData = obj
            // _this._api.publicApi.OCRsecond(_this.kfid, true)
            //     .then(res => {
            //       console.log(res)
            //     })
          }
        }
        if (res.ReportImages && res.ReportImages.length) {
          for (let i = 0; i < res.ReportImages.length; i++) {

            _this._api.publicApi.upFiles(res.ReportImages[i].ImageBase64, 'OM')
                .then(res => {
                  _this.upimglist.push(res)
                })

            /*let formData = new window.FormData() ;
            let blobDate = _this.convertBase64UrlToBlob( res.ReportImages[i].ImageBase64 );
            let url = window.URL.createObjectURL(blobDate)
            let stri = pf.randomString(6)+".png"
            formData.append(stri, blobDate); // 文件对象
            _this._http.post(pf.getUrl('UploadFile_Upload'), {
              "token": {
                "TimeSpan": 1566827733,
                "sToken": "768DCAFFF869CD61372790C2738012EC"
              },
              o: {
                FileBase64:  res.ReportImages[i].ImageBase64,
                ExtensionName: "."+formData.get(stri).type,
                UFSaveType: 'OSSPrivate',
                UFReObject: "Choose",
                UFType: 'OM',
                UFReId: _this.$store.state.physicianVisits.xzConsulting
              }
            })
                .then(r => {
                  if (r.Id) {
                    upwj.push({
                      name: r.Id,
                      raw: formData.get(stri),
                      status: "ready",
                      percentage: 0,
                      size: formData.get(stri).size,
                      uid: pf.randomString(18),
                      url
                    })
                  }
                })*/
          }
        }
        if (res.ReportPages && res.ReportPages.length) {
          for (let i = 0; i < res.ReportPages.length; i++) {
            _this._api.publicApi.upFiles(res.ReportPages[i].PageBase64, 'OM')
                .then(res => {
                  _this.upimglist.push(res)
                })

            /*let formData = new window.FormData() ;
            let blobDate = _this.convertBase64UrlToBlob( res.ReportPages[i].PageBase64 );
            let url = window.URL.createObjectURL(blobDate)
            let stri = pf.randomString(6)+".png"
            formData.append(stri, blobDate); // 文件对象
            _this._http.post(pf.getUrl('UploadFile_Upload'), {
              "token": {
                "TimeSpan": 1566827733,
                "sToken": "768DCAFFF869CD61372790C2738012EC"
              },
              o: {
                FileBase64:  res.ReportPages[i].PageBase64,
                ExtensionName: "."+formData.get(stri).type,
                UFSaveType: 'OSSPrivate',
                UFReObject: "choose",
                UFType: 'OM',
                UFReId: _this.$store.state.physicianVisits.xzConsulting
              }
            })
                .then(r => {
                  if (r.Id) {
                    upwj.push({
                      name: r.Id,
                      raw: formData.get(stri),
                      status: "ready",
                      percentage: 0,
                      size: formData.get(stri).size,
                      uid: pf.randomString(18),
                      url
                    })
                  }
                })*/
          }
        }
      }
    },

    save() {
      let OeyeBiologyTest = document.getElementById('eyeBiologyTest'),
          arrcw = OeyeBiologyTest.getElementsByClassName('cw')
      if (arrcw.length > 0) {
        this.$confirm('请复核红色区域数据是否异常！', '提示', {
          confirmButtonText: '继续提交',
          cancelButtonText: '取消',
          type: 'warning'
        })
            .then(() => {
              this._api.eyeTest.saveEyeBiologyTest()
            }).catch(() => {

            });
      }else {
        this._api.eyeTest.saveEyeBiologyTest()
      }

    },

    zh(key, type=0) {
      this.postData[key] = this.postData[key]?this.postData[key].toString():''
    },



    keyd(e) {
      if (pf.isMobile()) {
        e.returnValue= false
      }
      // e.returnValue= false
    },

    srjc(val, min, max) {
      if (val) {
        if (val.indexOf('^') > -1) return false
        if (isNaN(Number(val))) return true
        if (Number(val) > Number(max)) return true
        if (Number(val) < Number(min)) return true
        return false
      }
      return false
    },

    getgb(str, key, iszf = 0) {
      // console.log(e)
      // document.activeElement.blur();
      if (pf.isMobile()) {
        document.activeElement.blur();
      }
      this.keyval = str
      this.srval = key
      this.issr = true
      this.iszf = iszf
    },

    getXY(e) {
      if (this.issr) {
        this.$store.commit('isShow/upkeyh', e.y)
        this.showkeys = true
        this.issr = false
      }else  {
        this.showkeys = false
        this.srval = ''
      }

    },

    changeval(e) {
      this.keyval = e
      this.$set(this.postData, this.srval, e)
      if (this.postData[this.srval] && !isNaN(Number(this.postData[this.srval])) && this.iszf) {
        if (this.postData[this.srval] && Number(this.postData[this.srval]) > 0 && this.postData[this.srval].indexOf('+') < 0  && this.postData[this.srval].indexOf('-') < 0) {
          this.$set(this.postData, this.srval, '+'+e)
        }
      }
    }
  },
  components: {
    KEYS: () => import('@/components/keyboard/keyHome'),
    UPfile: () => import('@/components/CommonComponents/upFile'),
    UPFELES:() => import('../../components/sbData')
  }
}
</script>

<style scoped lang="scss">
  .eyeBiologyTest {
    width: 90%;
    margin: 0 auto;
  }
  h3{padding-bottom: 0.3rem}
  .xxxx {
    width: 100%;
    display: flex;
    justify-content: start;
    align-items: center;
    padding-top: 20px;
    li {
      width: 14vw;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    li:nth-child(1) {
      justify-content: start;
      width: 5%;
    }
    .pj {margin: 0.03rem 0.2rem;}
  }
  ::v-deep li input:hover {background: #e8e6e6}
  ::v-deep .xz input{background: #e8e6e6}
  .tp {
    display: flex;
    justify-content: start;
    padding: 0.4rem 0;
  }
  .xzlex {
    width: 200px;
    margin-right: 2vw;
  }
  .cw {background: #ef4674;}
  ::v-deep .cw  .el-input__inner{background: #ef4674 !important;}
  .jksjmgs {
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    background: rgba(0,0,0,0.8);
    z-index: 99999;
    display: flex;
    align-items: center;
    justify-content: center;
    iframe{
      width: 1000px;
      height: 500px;
      overflow: hidden;
    }
  }
</style>
